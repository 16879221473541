import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import referralIcon from 'assets/campain/kiwiReferral/referral_icon.png';
import dayjs from 'utils/dayjs';
import { openManageSubscriptionModal } from 'utils/globalLayerControl';
import { IRefferalInfo } from 'types/referral';
import { replaceCurlyBracesAndContent } from 'utils/common';

interface IProps {
    referralInfo: IRefferalInfo;
}

const KiwiReferralBanner = ({
    referralInfo,
}: IProps) => {
    const { startDateTime, endDateTime, referralTitle, refereeDiscountType, refereeDiscountValue } = referralInfo;
    const handleOfferNow = useCallback(() => {
        openManageSubscriptionModal('3');
    }, []);

    const titleRender = useMemo(() => {
        let string = '';
        if (refereeDiscountType === 'PERCENT_OFF') {
            string = `${refereeDiscountValue}%`;
        } else if (refereeDiscountType === 'AMOUNT_OFF') {
            string = `$${refereeDiscountValue}`;
        }

        return replaceCurlyBracesAndContent(referralTitle, string);
    }, [referralTitle, refereeDiscountType, refereeDiscountValue]);

    return (
        <div className={s.wrap}>
            <div className={s.leftWrap}>
                <img className={s.campaignIcon} src={referralIcon} />
                <div className={s.info}>
                    {
                        !startDateTime && !endDateTime ? '' : (
                            <div className={s.timeLimit}>Limited-time: {startDateTime ? dayjs(startDateTime || '').format('MMM DD') : ''} - {endDateTime ? dayjs(endDateTime || '').format('MMM DD') : ''}</div>
                        )
                    }
                    <div className={s.name}>{titleRender}</div>
                </div>
            </div>
            <div className={s.rightWrap}>
                <div className={`${s.button} ${s.joinBtn}`} onClick={handleOfferNow}>Refer now</div>
            </div>
        </div>
    );
};

export default KiwiReferralBanner;
