import React, { useCallback, useEffect, useMemo } from 'react';
import s from './s.module.less';
import { Button, message } from 'antd';
import { shareToDoximity, shareToFacebook, shareToLinkedin, shareToTwitter } from 'utils/share';
import { generateProviderReferralLink } from 'api/referral';
import { getUserId, getReferralLinkMap, setReferralLinkMap } from 'utils/localstore';
import copy from 'copy-to-clipboard';
import { IRefferalInfo } from 'types/referral';
import dayjs from 'utils/dayjs';
import { removeHtmlTagButWrap, replaceCurlyBracesAndContent } from 'utils/common';

interface IProps {
    referralInfo?: IRefferalInfo;
}

const InviteBanner = ({
    referralInfo,
}: IProps) => {
    const [generateLoading, setGenerateLoading] = React.useState<boolean>(false);
    const [providerReferralLink, setProviderReferralLink] = React.useState<string>();
    const emailMsgContent = referralInfo?.emailMsgContent || '';
    const referralTitle = referralInfo?.referralTitle || '';
    const startDateTime = referralInfo?.startDateTime || '';
    const endDateTime = referralInfo?.endDateTime || '';
    const [shareUrl, setShareUrl] = React.useState<string>('');
    const [showReferalLinl, setShowReferalLinl] = React.useState<boolean>(false);

    const discountValue = useMemo(() => {
        if (referralInfo?.refereeDiscountType === 'PERCENT_OFF') {
            return `${referralInfo.refereeDiscountValue}%`;
        } else if (referralInfo?.refereeDiscountType === 'AMOUNT_OFF') {
            return `$${referralInfo.refereeDiscountValue}`;
        }

        return '$0';
    }, [referralInfo]);

    const shareTitle = replaceCurlyBracesAndContent(referralTitle, discountValue);

    useEffect(() => {
        if (providerReferralLink) {
            setShareUrl(providerReferralLink);
        }
    }, [providerReferralLink]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const providerId = getUserId();
            const referralLinkMapString = getReferralLinkMap();

            if (referralLinkMapString && providerId) {
                try {
                    const referralMap = JSON.parse(referralLinkMapString);
                    const link = referralMap[providerId];
                    setShowReferalLinl(!!link);
                    // setProviderReferralLink(link);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }, []);

    const setReferralLinkToLocalStorage = useCallback((referralLink: string) => {
        if (typeof window !== 'undefined') {
            const providerId = getUserId();
            if (providerId) {
                const referralLinkMapString = getReferralLinkMap();
                let referralMap = {};

                if (referralLinkMapString) {
                    try {
                        referralMap = JSON.parse(referralLinkMapString);
                    } catch (e) {
                        console.error(e);
                    }
                }

                referralMap = {
                    ...referralMap,
                    [providerId]: referralLink,
                };
                setReferralLinkMap(referralMap);
            }
        }
    }, []);

    const handleCopyButtonClick = useCallback(() => {
        if (providerReferralLink) {
            copy(providerReferralLink, {
                format: 'text/plain',
            });
            message.success('Copied to the clipboard');
        }
    }, [providerReferralLink]);

    const handleReferralMessageCopy = useCallback(() => {
        if (emailMsgContent) {
            copy(emailMsgContent, {
                format: 'text/plain',
            });
            message.success('Copied to the clipboard');
        }
    }, [emailMsgContent]);

    const handleGenerateReferralLink = useCallback(async () => {
        setGenerateLoading(true);

        try {
            const res = await generateProviderReferralLink();

            if (res && !res.error) {
                const link = res.data.data;
                if (link) {
                    setProviderReferralLink(link);
                }
            } else {
                message.error(res.error);
            }
        } catch (error) {
            console.log('error', error);
        }
        setGenerateLoading(false);
    }, []);

    useEffect(() => {
        handleGenerateReferralLink();
    }, [handleGenerateReferralLink]);

    const handleGenerateButtonClick = useCallback(() => {
        setShowReferalLinl(true);
        setReferralLinkToLocalStorage('true');
    }, []);

    const handleEmailClick = useCallback(() => {
        const recipient = ''; // 收件人邮箱地址
        const subject = shareTitle; // 邮件主题
        const emailBody = emailMsgContent || '';

        // 构建 mailto 链接
        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(removeHtmlTagButWrap(emailBody.replace(/<br\s*\/?>/gi, '\n')))}`;

        // 打开邮箱客户端
        window.location.href = mailtoLink;
    }, [emailMsgContent, shareTitle]);

    const contentRender = useMemo(() => {
        if (!showReferalLinl) {
            return (
                <div className={s.getReferLinkButtonBox}>
                    <Button type="primary" loading={generateLoading} className={s.getReferLinkButton} onClick={handleGenerateButtonClick}>Get your referral link</Button>
                    <div className={s.termsText}>By getting the code you’re agreeing to <a className={s.link}>Kiwi Health’s Terms and Conditions</a>.</div>
                </div>
            );
        }

        return (
            <div className={s.shareBox}>
                <div className={s.shareTitle}>Share your unique referral link</div>
                <div className={s.shareLinkBox}>
                    <div className={s.shareText}>{providerReferralLink}</div>
                    <Button onClick={handleCopyButtonClick}>Copy</Button>
                </div>
                <div className={s.ways}>
                    <div className={s.waysTitle}>Or share your referral link via</div>
                    <div className={s.shareButtons}>
                        <div className={`${s.shareBtn} ${s.email}`} onClick={() => handleEmailClick()} />
                        <div className={`${s.shareBtn} ${s.facebook}`} onClick={() => shareToFacebook(shareUrl)} />
                        <div className={`${s.shareBtn} ${s.linkedin}`} onClick={() => shareToLinkedin(shareUrl, shareTitle)} />
                        <div className={`${s.shareBtn} ${s.twitter}`} onClick={() => shareToTwitter(shareUrl, shareTitle)} />
                        <div className={`${s.shareBtn} ${s.doximity}`} onClick={() => shareToDoximity(shareUrl)} />
                    </div>
                </div>
                <div className={s.copyTemplate}>
                    <div className={s.templateTitle}>Copy an example referral message</div>
                    <div className={s.templateContentBox}>
                        <div className={s.templateContent} dangerouslySetInnerHTML={{ __html: emailMsgContent }} />
                        <Button onClick={handleReferralMessageCopy}>Copy</Button>
                    </div>
                </div>
            </div>
        );
    }, [providerReferralLink, handleCopyButtonClick, emailMsgContent, handleReferralMessageCopy, generateLoading, handleEmailClick, shareUrl, shareTitle, showReferalLinl, handleGenerateButtonClick]);

    return (
        <div className={s.wrap}>
            <div className={s.title}>Refer a provider and earn rewards</div>
            <div className={s.desc}>{`From ${dayjs(startDateTime).format('MM/DD/YYYY')} to ${dayjs(endDateTime).format('MM/DD/YYYY')}, refer a provider to Kiwi Health, and both of you will save ${discountValue} on your next payment! The referee needs to sign up and upgrade to any plan with a quarterly, semi-annual, or yearly billing frequency.`}</div>
            {
                contentRender
            }
        </div>
    );
};

export default InviteBanner;
