import React from 'react';
import s from './s.module.less';

interface IProps {
    status: 'A' | 'R';
}

const StatusTag = ({ status }: IProps) => {
    const text = status === 'A' ? 'Active' : 'Redeemed';
    const cls = status === 'A' ? s.active : s.redeemed;
    return (
        <div className={`${s.wrap} ${cls}`}>{text}</div>
    );
};

export default StatusTag;
