import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { Input, message, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import dayjs from 'utils/dayjs';
import { getProviderRewardReferralsList } from 'api/referral';
import { IGetRewardReferralsListInput } from 'types/referral';
import StatusTag from './StatusTag';

const HowItWorks = () => {
    return (
        <div className={s.howItWordsWrap}>
            <div className={s.title}>Refer a provider and get paid</div>
            <ul>
                <li>
                    <div className={`${s.icon} ${s.refer}`} />
                    <div className={s.content}>
                        <div className={s.title}>Refer a provider</div>
                        <div className={s.decs}>Invite another provider to join Kiwi Health by sharing your referral link.</div>
                    </div>
                </li>
                <li>
                    <div className={`${s.icon} ${s.money}`} />
                    <div className={s.content}>
                        <div className={s.title}>Earn your reward</div>
                        <div className={s.decs}>Invite another provider to join Kiwi Health by sharing your referral link.</div>
                    </div>
                </li>
                <li>
                    <div className={`${s.icon} ${s.gift}`} />
                    <div className={s.content}>
                        <div className={s.title}>Enjoy your reward</div>
                        <div className={s.decs}>Once the referred provider signs up and upgrades to an eligible Kiwi plan, your reward discount will be automatically applied to your next payment cycle.</div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

const RewordsTable = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const statusFilterOptions = useRef([
        {
            text: 'Active',
            value: 'A',
        },
        {
            text: 'Redeemed',
            value: 'R',
        },
    ]);

    const pramas = useRef<IGetRewardReferralsListInput>({
        refereeEmail: '',
        rewardName: '',
        status: undefined,
        RewardDateSort: null,
        size: 500,
        current: 1,
    });

    const getRewardReferralList = useCallback(async (tableData?:IGetRewardReferralsListInput) => {
        setLoading(true);
        try {
            const p = tableData || pramas.current;
            const res = await getProviderRewardReferralsList(p);

            if (res && !res.error) {
                setData(res.data?.data?.records || []);
            } else {
                message.error(res?.error);
            }
        } catch (error) {
            console.log('error', error);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        getRewardReferralList();
    }, [getRewardReferralList]);

    const filterNameDebounce = useCallback(debounce((params: any) => {
        pramas.current = {
            ...pramas.current,
            ...params,
        };
        getRewardReferralList(pramas.current);
    }, 500), []);

    const handleTableChange = useCallback((paginationData?: TablePaginationConfig, filtersData?: Partial<IGetRewardReferralsListInput>, sorter?: any, action?: any) => {
        const newParams:IGetRewardReferralsListInput = {
            ...pramas.current,
            status: filtersData?.status,
            RewardDateSort: null,
        };
        if (filtersData && filtersData.status) {
            if (filtersData.status.length === statusFilterOptions.current.length) {
                newParams.status = undefined;
            } else {
                newParams.status = filtersData?.status?.join(',');
            }
        }
        if (sorter.field === 'rewardDate') {
            if (sorter.order) {
                newParams.RewardDateSort = sorter.order !== 'ascend';
            } else {
                newParams.RewardDateSort = null;
            }
        }

        getRewardReferralList(newParams);
    }, []);

    const columns: ColumnsType<any> = useMemo(() => (
        [
            {
                title: 'REWARDS',
                dataIndex: 'rewardName',
                filterSearch: true,
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                editable: true,
                width: '150px',
                fixed: 'left',
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            onChange={(e) => {
                                const rewardName = e.target.value;
                                setTimeout(() => {
                                    filterNameDebounce({
                                        rewardName,
                                    });
                                }, 0);
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
            },
            {
                title: 'REWARDS STATUS',
                dataIndex: 'status',
                width: '300px',
                className: s.tableColumn,
                filters: statusFilterOptions.current,
                render: (status) => <StatusTag status={status} />,
            },
            {
                title: 'REWARDS DATE',
                dataIndex: 'rewardDate',
                width: '320px',
                className: s.tableColumn,
                sorter: true,
                render: (updateTime) => updateTime && dayjs(updateTime).format('MM/DD/YYYY'),
            },
            {
                title: 'Redeem Date',
                dataIndex: 'redeemDate',
                width: '200px',
                className: s.tableColumn,
                render: (updateTime) => updateTime && dayjs(updateTime).format('MM/DD/YYYY'),
            },
            {
                title: 'REFEREE',
                dataIndex: 'refereeEmail',
                width: '120px',
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            onChange={(e) => {
                                const refereeEmail = e.target.value;
                                setTimeout(() => {
                                    filterNameDebounce({
                                        refereeEmail,
                                    });
                                }, 0);
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
            },
        ]
    ), [filterNameDebounce]);

    return (
        <div className={s.tableWrap}>
            <div className={s.title}>Rewards for you</div>
            <CommonTable
                pagination={false}
                rowKey={(record) => record.id}
                bordered
                loading={loading}
                columns={columns}
                data={data}
                onChange={handleTableChange}
            />
        </div>
    );
};

const ReferDetailInfo = () => {
    const [activeKey, setActiveKey] = useState<string>('1');
    const contentRender = useMemo(() => {
        return activeKey === '1' ? <HowItWorks /> : <RewordsTable />;
    }, [activeKey]);

    return (
        <div className={s.wrap}>
            <div className={s.btns}>
                <div className={`${s.btn} ${activeKey === '1' ? s.actived : ''}`} onClick={() => setActiveKey('1')}>How it works</div>
                <div className={`${s.btn} ${activeKey === '2' ? s.actived : ''}`} onClick={() => setActiveKey('2')}>Rewards</div>
            </div>
            {contentRender}
        </div>
    );
};

export default ReferDetailInfo;
